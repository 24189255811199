import Carousel from 'react-bootstrap/Carousel';
import {
    Container,
    Img,
} from "@chakra-ui/react";

const image1 = "https://www.communityangelsinc.com/wp-content/uploads/2023/04/home-care-maryland-1200x800.jpg";
const image2 = "https://www.bxtimes.com/wp-content/uploads/2022/06/GettyImages-1343671737-1200x800.jpg";
const image3 = "https://www.reviewjournal.com/wp-content/uploads/2023/12/18673937_web1_GettyImages-1598602797-1.jpg";

function IndividualIntervalsExample() {
  return (
    <Container centerContent>
      <Carousel style={{ width: "70%", textAlign: "center" }} wrap={true}>
        <Carousel.Item interval={5000}>
          <Img src={image1} />
          {/* <Carousel.Caption>
            <h3>First slide label</h3>
            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
          </Carousel.Caption> */}
        </Carousel.Item>
        <Carousel.Item interval={5000}>
          <Img src={image2} data-testid="test2 image" />
          {/* <Carousel.Caption>
            <h3>Second slide label</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          </Carousel.Caption> */}
        </Carousel.Item>
        <Carousel.Item interval={5000}>
          <Img src={image3} />
          {/* <Carousel.Caption>
            <h3>Third slide label</h3>
            <p>
              Praesent commodo cursus magna, vel scelerisque nisl consectetur.
            </p>
          </Carousel.Caption> */}
        </Carousel.Item>
      </Carousel>
    </Container>
  );
}

export default IndividualIntervalsExample;
