import Home from './pages/Home';
import About from './pages/About';
import Hospitals from './pages/Hospitals';
import ServicesAndEvents from './pages/ServicesAndEvents';
import ElderlyHomes from './pages/ElderlyHomes';
import HospitalInstance from './components/HospitalInstance';
import EventInstance from './components/EventInstance';
import HomeInstance from './components/HomeInstance';
import SearchResults from './pages/SearchResults';
import SearchResultsV2 from './pages/SearchResultsV2';
import Visualizations from './pages/Visualizations';
import ProviderVisualizations from './pages/ProviderVisualizations';

const routes = [
    { path: '/', component: <Home />, exact: true },
    { path: '/about', component: <About /> },
    { path: '/Hospitals', component: <Hospitals /> },
    { path: '/Events', component: <ServicesAndEvents /> },
    { path: '/ElderlyHomes', component: <ElderlyHomes /> },
    { path: '/Hospitals/:id', component: <HospitalInstance /> },
    { path: '/Events/:id', component: <EventInstance /> },
    { path: '/ElderlyHomes/:id', component: <HomeInstance /> },
    { path: '/search-results/:searchTerm', component: <SearchResults /> },
    { path: '/search/:searchTerm', component: <SearchResultsV2 /> },
    { path: '/Visualizations', component: <Visualizations /> },
    { path: '/ProviderVisualizations', component: <ProviderVisualizations /> },

];

export default routes;