import React, { useState, useEffect, useRef } from 'react';
import * as d3 from 'd3';
import axios from 'axios';

const Treemap = () => {
  const chartRef = useRef();
  const width = 900;
  const height = 500;
  const margin = { top: 0, right: 0, bottom: 0, left: 0 };
  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;

  const url = `https://cs373backend.elderhelpertexas.me/events/`;
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(url);
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (chartRef.current && data.length) {
      drawChart();
    }
  }, [data]);

  const drawChart = () => {
    d3.select(chartRef.current).selectAll('svg').remove();
    
    // Extract event categories from data
    const eventCategories = data.map(event => event.event_category);

    // Count occurrences of each event category
    const categoryCounts = eventCategories.reduce((acc, category) => {
      acc[category] = (acc[category] || 0) + 1;
      return acc;
    }, {});

    // Convert categoryCounts to an array of objects for treemap
    const treemapData = {
      name: 'root',
      children: Object.keys(categoryCounts).map(category => ({
        name: category,
        value: categoryCounts[category]
      }))
    };

    // Create treemap layout
    const treemap = d3.treemap()
      .size([innerWidth, innerHeight])
      .padding(1);

    const root = d3.hierarchy(treemapData)
      .sum(d => d.value)
      .sort((a, b) => b.value - a.value);

    treemap(root);

    const svg = d3.select(chartRef.current)
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', `translate(${margin.left}, ${margin.top})`);

    // Create treemap cells
    const cells = svg.selectAll('g')
      .data(root.leaves())
      .enter()
      .append('g')
      .attr('transform', d => `translate(${d.x0},${d.y0})`);

    cells.append('rect')
      .attr('width', d => d.x1 - d.x0)
      .attr('height', d => d.y1 - d.y0)
      .attr('fill', 'green');

    // Add text
    cells.append('text')
      .attr('x', 5)
      .attr('y', 15)
      .attr('fill', 'white')
      .text(d => {
        const textWidth = d.x1 - d.x0 - 10;
        const text = `${d.data.name} (${d.data.value})`; // Add number to category name
        const textLength = text.length;
        if (textLength > textWidth / 8) {
          return text.slice(0, Math.floor(textWidth / 8)) + '...';
        } else {
          return text;
        }
      });

    // Add tooltip
    cells.on('mouseover', function (event, d) {
      d3.select(this).select('rect').attr('fill', 'lightgreen');
      // Append tooltip
      d3.select(chartRef.current)
        .append('div')
        .attr('class', 'tooltip')
        .style('opacity', 0.9)
        .html(`${d.data.name} (${d.data.value})`) // Add number to tooltip
        .style('left', `${event.pageX}px`)
        .style('top', `${event.pageY}px`)
        .style('font-size', '24px')
        .style('font-weight', 'bold');
    })
    .on('mouseout', function () {
      d3.select(this).select('rect').attr('fill', 'green');
      // Remove tooltip
      d3.select('.tooltip').remove();
    });

  };

  return <div ref={chartRef}></div>;
};

export default Treemap;
