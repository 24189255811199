import React, { useState, useEffect, useRef } from 'react';
import * as d3 from 'd3';
import axios from 'axios';

const HospitalChart = () => {
  const chartRef = useRef();
  const width = 1000;
  const height = 500;
  const margin = { top: 30, right: 40, bottom: 110, left: 80 };
  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;

  const url = `https://cs373backend.elderhelpertexas.me/hospitals/`;
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(url);
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (chartRef.current && data.length) {
      drawChart();
    }
    // eslint-disable-next-line
  }, [data]);

  const drawChart = () => {
    d3.select(chartRef.current).selectAll('svg').remove();

    // Calculates the hospital frequencies for each city
    const rawCities = {};
    data.forEach((hospital) => {
        const city = hospital.city;
        if (rawCities[city] === undefined) rawCities[city] = 1;
        else rawCities[city] += 1;
    });

    // Filters by cities with at least four hospitals, and calculates
    // values for other variables
    const cities = Object.entries(rawCities).filter(([k, v]) => v >= 4);
    const cityNames = cities.map(([k, v]) => k).sort();
    const maxCount = Math.max(...cities.map(([k, v]) => v));
    const cityBarData = cities.map(([k, v]) => ({ city: k, value: v }));

    // Set up the scales
    const xScale = d3.scaleBand()
      .domain(cityNames)
      .range([0, innerWidth])
      .padding(0.1);

    const yScale = d3.scaleLinear()
      .domain([0, maxCount])
      .nice()
      .range([innerHeight, 0]);

    // Create an SVG element
    const svg = d3.select(chartRef.current)
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', `translate(${margin.left}, ${margin.top})`);

    // Create bars with animation
    svg.selectAll('rect')
      .data(cityBarData)
      .enter()
      .append('rect')
      .attr('x', d => xScale(d.city))
      .attr('y', innerHeight) // Start the bars at the bottom of the graph
      .attr('width', xScale.bandwidth())
      .attr('height', 0) // Initially set the height to 0
      .attr('fill', 'green')
      .transition() // Add transition for the bars
      .duration(1000) // Animation duration
      .attr('y', d => yScale(d.value))
      .attr('height', d => innerHeight - yScale(d.value));

    // Add mouseover interaction
    svg.selectAll('rect')
      .on('mouseover', function () {
        d3.select(this).attr('fill', 'lightgreen');
      })
      .on('mouseout', function () {
        d3.select(this).attr('fill', 'green');
      });

    // Add x-axis
    svg.append('g')
      .attr('transform', `translate(0, ${innerHeight})`)
      .call(d3.axisBottom(xScale))
      .selectAll('text')
      .style('text-anchor', 'end')
      .attr('dx', '-.8em')
      .attr('dy', '.15em')
      .attr('transform', 'rotate(-45)')
      .style('font-weight', 'bold') // Set font weight to bold for x-axis labels
      .style('font-size', '11px');

    // Add y-axis
    svg.append('g')
      .call(d3.axisLeft(yScale));

    // Add x-axis label
    svg.append('text')
      .attr('x', innerWidth / 2)
      .attr('y', innerHeight + margin.top + 70)
      .attr('text-anchor', 'middle')
      .text('Cities')
      .style('font-weight', 'bold')
      .style('font-size', '24px');

    // Add y-axis label
    svg.append('text')
      .attr('transform', `translate(${-margin.left + 20},${innerHeight / 2}) rotate(-90)`)
      .attr('text-anchor', 'middle')
      .text('Hospital Frequency')
      .style('font-weight', 'bold')
      .style('font-size', '24px');
  };

  return <div ref={chartRef}></div>;
};

export default HospitalChart;
