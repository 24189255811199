import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import styles from './NavBar.module.css';
import logo from './assets/logo.svg'; 
import colors from './colors';
import SearchBar from './components/SearchBar'

const NavBar = () => {

    const handleSearch = (searchTerm) => {
        // Perform search logic here
        console.log('Searching for:', searchTerm);
        // Redirect to search results page with the search term as a URL parameter
        window.location.href = `/search/${encodeURIComponent(searchTerm)}`;
    };
        // Inline styles for the SearchBar
        const searchBarStyle = {
            marginRight: '1000px', // Adjust the spacing between the Navbar links and the search bar
        };


    return (
        <Navbar expand="lg" className={styles.navbar} style={{ backgroundColor: colors.primary }}>
            <Container className={styles.navbarContainer} style={{ maxWidth: '5000px' }}>
                <Navbar.Brand as={NavLink} to="/" className={styles.logoContainer}>
                    <img src={logo} alt="Logo" className={styles.logo} />
                    <span className={styles.elderHelper}>ElderHelperTexas</span>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                        <Nav.Link as={NavLink} to="/" className={styles.navLink}>
                            <span className={styles.navText}>Home</span>
                        </Nav.Link>
                        <Nav.Link as={NavLink} to="/about" className={styles.navLink}>
                            <span className={styles.navText}>About</span>
                        </Nav.Link>
                        <Nav.Link as={NavLink} to="/Hospitals" className={styles.navLink}>
                            <span className={styles.navText}>Hospitals</span>
                        </Nav.Link>
                        <Nav.Link as={NavLink} to="/Events" className={styles.navLink}>
                            <span className={styles.navText}>Events</span>
                        </Nav.Link>
                        <Nav.Link as={NavLink} to="/ElderlyHomes" className={styles.navLink}>
                            <span className={styles.navText}>Elderly Homes</span>
                        </Nav.Link>
                        <Nav.Link as={NavLink} to="/Visualizations" className={styles.navLink}>
                            <span className={styles.navText}>Visualizations</span>
                        </Nav.Link>
                        <Nav.Link as={NavLink} to="/ProviderVisualizations" className={styles.navLink}>
                            <span className={styles.navText}>Provider Visualizations</span>
                        </Nav.Link>
                    </Nav>
                    <SearchBar onSearch={handleSearch} style={searchBarStyle} />
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default NavBar;
