import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import InstanceTemplate from "./InstanceTemplate";
import EventsCardAxios from './EventsCardAxios';
import HospitalCardAxios from './HospitalCardAxios';

const HomeInstance = () => {
  const {id} = useParams();
  const url = `https://cs373backend.elderhelpertexas.me/elderhomes/${id}`;
  const [info, setInfo] = useState({dataObject: null});

  useEffect(() => {
    const retrieve = async () => {
      const response = await axios.get(url);
      setInfo({dataObject: response.data});
    };
    retrieve();
  }, [url]);

  const data = info.dataObject;
  if (data == null) return (<div></div>);

  const {
    city, display_name, formatted_address, image_url, national_phone_number,
    rating, related_events, related_hospitals, website_url
  } = data;

  return (
    <InstanceTemplate 
      title={display_name}
      content={
        `${display_name} is located in ${formatted_address}, which is in \
        ${city}, Texas. They have a rating of ${rating} out of 5 stars. They \
        can be reached at the phone number ${national_phone_number}. \
        Additionally, more information is available on their website: \
        ${website_url}.`
      }
      image={image_url}
      location={formatted_address}
      summary={{
        'City': city,
        'Address': formatted_address,
        'Phone Number': national_phone_number,
        'Website': <a href={website_url}>{website_url}</a>,
        'Rating': rating
      }}
      relatedDetails1={{
        name: 'Hospitals',
        data: related_hospitals,
        AxiosCardType: HospitalCardAxios
      }}
      relatedDetails2={{
        name: 'Events',
        data: related_events,
        AxiosCardType: EventsCardAxios
      }}
    />
  );
}

export default HomeInstance;
