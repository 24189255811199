import React from 'react';
import './InstanceTemplate.css';
import GoogleMapsComponent from './GoogleMapsComponent';

const Summary = ({ summary }) => {
  return (
    <div className='summary'>
      {summary && Object.entries(summary).map((element, _) => (
        <p><b>{element[0]}:</b> {element[1]}</p>
      ))}
    </div>
  );
}

const RelatedGroup = ({ name, data, AxiosCardType }) => {
  return (
    <div className='related-group'>
      <h2 className='related-title'>Related {name}</h2>
      <div className='related-card-grid'>
        {data.slice(0, 4).map((id) => (
          <AxiosCardType key={id} id={id} className='related-card' />
        ))}
      </div>
    </div>
  );
}

const InstanceTemplate = ({ title, content, image, location, summary,
    relatedDetails1, relatedDetails2 }) => {
  return (
    <div className='instance-page'>
      <h1 className='title'>{title}</h1>
      <div className='instance-media'>
        <img className='image' src={image} alt={title} />
        <div className='maps-wrapper'>
          {location && <GoogleMapsComponent address={location} />}
        </div>
      </div>
      <div className='info'>
        <div className='content'>
          {content && <p>{content}</p>}
        </div>
        <Summary title={title} summary={summary} />
      </div>
      <h1 className='related-main-title'>Related Instances</h1>
      <div className='related-grid'>
        <RelatedGroup {...relatedDetails1} />
        <RelatedGroup {...relatedDetails2} />
      </div>
    </div>
  );
}

export default InstanceTemplate;
