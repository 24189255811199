import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import axios from 'axios';

const LogScatterPlot = () => {
  const chartRef = useRef();
  const [data, setData] = useState([]);

  const width = 1300;
  const height = 500;
  const margin = { top: 40, right: 30, bottom: 70, left: 70 };
  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;

  const fetchData = async () => {
    try {
      const response = await axios.get(
        'https://cs373-backend.literatetx.me/get_libraries'
      );
      // Filter out libraries with zero square footage and zero audio/video items
      const filteredData = response.data.result.filter(
        (d) =>
          d.square_footage > 700 &&
          (d.num_audio_items > 0 || d.num_video_items > 0)
      );
      setData(filteredData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (data.length > 0 && chartRef.current) {
      drawChart();
    }
  }, [data]);

  const drawChart = () => {
    d3.select(chartRef.current).selectAll('svg').remove(); // Clear existing SVG content

    const svg = d3.select(chartRef.current)
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', `translate(${margin.left}, ${margin.top})`);

    // Create logarithmic scales
    const xScale = d3.scaleLog()
      .domain([700, d3.max(data, (d) => d.square_footage)]) // Starting from 700 square footage
      .range([0, innerWidth]);

    const yScale = d3.scaleLog()
      .domain([
        1,
        d3.max(data, (d) => Math.max(d.num_video_items, d.num_audio_items)),
      ])
      .range([innerHeight, 0]);

    // Tooltip for hover interaction
    const tooltip = d3.select('body')
      .append('div')
      .style('position', 'absolute')
      .style('background', '#f9f9f9')
      .style('padding', '10px')
      .style('border', '1px solid #ccc')
      .style('border-radius', '5px')
      .style('display', 'none');

    // Create scatter plot for video items
    svg.selectAll('.video-dot')
      .data(data)
      .enter()
      .filter((d) => d.num_video_items > 0) // Only keep entries with more than zero video items
      .append('circle')
      .attr('class', 'video-dot')
      .attr('cx', (d) => xScale(d.square_footage))
      .attr('cy', (d) => yScale(d.num_video_items))
      .attr('r', 6)
      .attr('fill', 'blue')
      .on('mouseover', function (event, d) {
        d3.select(this).transition().attr('r', 8); // Enlarge dot on hover
        tooltip
          .style('display', 'block')
          .html(`
            <strong>${d.name}</strong><br />
            City: ${d.city}<br />
            Square Footage: ${d.square_footage}<br />
            Video Items: ${d.num_video_items}
          `);
      })
      .on('mousemove', function (event) {
        tooltip
          .style('left', `${event.pageX + 10}px`)
          .style('top', `${event.pageY + 10}px`);
      })
      .on('mouseout', function () {
        d3.select(this).transition().attr('r', 6); // Revert dot size
        tooltip.style('display', 'none');
      });

    // Create scatter plot for audio items
    svg.selectAll('.audio-dot')
      .data(data)
      .enter()
      .filter((d) => d.num_audio_items > 0) // Only keep entries with more than zero audio items
      .append('circle')
      .attr('class', 'audio-dot')
      .attr('cx', (d) => xScale(d.square_footage))
      .attr('cy', (d) => yScale(d.num_audio_items))
      .attr('r', 6)
      .attr('fill', 'orange')
      .on('mouseover', function (event, d) {
        d3.select(this).transition().attr('r', 8); // Enlarge dot on hover
        tooltip
          .style('display', 'block')
          .html(`
            <strong>${d.name}</strong><br />
            City: ${d.city}<br />
            Square Footage: ${d.square_footage}<br />
            Audio Items: ${d.num_audio_items}
          `);
      })
      .on('mousemove', function (event) {
        tooltip
          .style('left', `${event.pageX + 10}px`)
          .style('top', `${event.pageY + 10}px`);
      })
      .on('mouseout', function () {
        d3.select(this).transition().attr('r', 6); // Revert dot size
        tooltip.style('display', 'none');
      });

    // Add x-axis
    svg.append('g')
      .attr('transform', `translate(0, ${innerHeight})`)
      .call(d3.axisBottom(xScale).ticks(10, '~s')); // Use shorthand notation for ticks

    // Add y-axis
    svg.append('g')
      .call(d3.axisLeft(yScale).ticks(10, '~s')); // Use shorthand notation for ticks

    // Add x-axis label
    svg.append('text')
      .attr('x', innerWidth / 2)
      .attr('y', innerHeight + margin.bottom - 30) // Position below the axis
      .attr('text-anchor', 'middle')
      .style('font-weight', 'bold')
      .style('font-size', '16px')
      .text('Square Footage (Logarithmic)');

    // Add y-axis label
    svg.append('text')
      .attr('transform', 'rotate(-90)')
      .attr('x', -innerHeight / 2)
      .attr('y', -margin.left + 20)
      .attr('text-anchor', 'middle')
      .style('font-weight', 'bold')
      .style('font-size', '16px')
      .text('Number of Items (Logarithmic)');

    // Add a title to the scatter plot
    svg.append('text')
      .attr('x', innerWidth / 2)
      .attr('y', -10) // Position above the scatter plot
      .attr('text-anchor', 'middle')
      .style('font-weight', 'bold')
      .style('font-size', '20px')
    //   .text('Scatter Plot: Libraries by Square Footage and Items');
  };

  return <div ref={chartRef}></div>;
};

export default LogScatterPlot;
