import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import 'bootstrap/dist/css/bootstrap.min.css';
import Hospitals from '../pages/Hospitals'
import Events from '../pages/ServicesAndEvents'
import Homes from '../pages/ElderlyHomes'
import { useParams } from 'react-router-dom';






function JustifiedExample() {

const { searchTerm } = useParams();

  return (
    <Tabs
      defaultActiveKey="Hospitals"
      id="justify-tab-example"
      className="mb-3"
      justify
    >
      <Tab eventKey="Hospitals" title={<span style={{ color: '#239056' }}>Hospitals</span>}>
        <Hospitals customString = {searchTerm} />
      </Tab>
      <Tab eventKey="Events" title={<span style={{ color: '#239056' }}>Events</span>}>
        <Events customString = {searchTerm} />
      </Tab>
      <Tab eventKey="Elderly Homes" title={<span style={{ color: '#239056' }}>Elderly Homes</span>}>
        <Homes customString = {searchTerm} />
      </Tab>

    </Tabs>
  );
}

export default JustifiedExample;