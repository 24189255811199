import React from 'react';
import Treemap from '../components/TreeMap';
import ScatterPlot from '../components/ScatterPlot';
import HospitalChart from '../components/HospitalChart';

const Visualizations = () => {
  return (
    <div style={{ textAlign: 'center' }}>
      <div style={{ marginBottom: '40px' }}> {/* Add margin-bottom */}
        <h1>Event Categories</h1>
        <Treemap />
        <h1>Elderly Homes City Ratings</h1>
        <ScatterPlot />
        <h1>Most Frequent Hospital Locations</h1>
        <HospitalChart />
      </div>
    </div>
  );
};

export default Visualizations;
