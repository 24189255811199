import React, { useState, useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactPaginate from 'react-paginate';
import axios from 'axios';
import HospitalCard from '../components/HospitalCard'; // Import the HospitalCard component
import CustomDropdown from '../components/CustomDropdown';
import SearchBar from '../components/SearchBar';

const Hospitals = ({ customString }) => {
  const [hospitalData, setHospitalData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const itemsPerPage = 12; // Define how many items you want per page
  const [currentPage, setCurrentPage] = useState(0);
  const [sortBy, setSortBy] = useState("Name");
  const [orderBy, setOrderBy] = useState("Ascending");
  // const [selectedCity, setSelectedCity] = useState("All Cities");
  const hospitalParams = ["Name", "City", "Beds", "Revenue", "Days", "Discharges"];
  const orders = ["Ascending", "Descending"];
  // const cities = ["All Cities", "San Antonio", "Austin", "Houston", "Dallas"];

  useEffect(() => {
    const getSortParamForApi = () => {
      const apiMapping = {
        "Name": "hospitalname",
        "City": "city",
        "Beds": "beds",
        "Revenue": "grosspatientrevenue",
        "Discharges": "totaldischarges",
        "Days": "patientdays"
      };
  
      const apiParam = apiMapping[sortBy] || 'hospitalname';
      const mapOrderToParam = (order) => order === "Ascending" ? "asc" : "desc";
      const orderSuffix = mapOrderToParam(orderBy);
      return `${apiParam}${orderSuffix}`;
    };
  
    const fetchData = async () => {
      try {
        let url = 'https://cs373backend.elderhelpertexas.me/hospitals/';
        if (customString && customString.trim() !== '') {
          url += `?search=${customString}`;
          handleSearch2(customString);
        } else if (searchTerm) {
          url += `?search=${searchTerm}`;
        }
        
        const sortParam = getSortParamForApi();
        if (searchTerm || customString) {
          url += `&sort_order=${sortParam}`;
        } else {
          url += `?sort_order=${sortParam}`;
        }
        
        console.log(url);
        const response = await axios.get(url);
        setHospitalData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
        setHospitalData([]);
      }
    };
  
    fetchData();
  }, [customString, searchTerm, sortBy, orderBy, currentPage]);


  const totalItems = hospitalData.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const startIndex = currentPage * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, totalItems);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  const handleSortBy = (item) => {
    console.log(item);
    setSortBy(item);
    setCurrentPage(0);
  };

  const handleOrderBy = (order) => {
    setOrderBy(order);
    setCurrentPage(0);

  };

  // const handleCitySelect = (city) => {
  //   setSelectedCity(city);
  //   setCurrentPage(0);
  // };

  const handleSearch = (searchTerm) => {
    if (customString){
      setSearchTerm(customString);
    }else{
      setSearchTerm(searchTerm);
    }
    setCurrentPage(0); // Reset to the first page when searching
  };

  const handleSearch2 = (searchTerm) => {
    if (customString){
      setSearchTerm(customString);
    }else{
      setSearchTerm(searchTerm);
    } // Reset to the first page when searching
  };





  return (
<div style={{ width: '100%', paddingRight: '15px', paddingLeft: '15px', marginRight: 'auto', marginLeft: 'auto' }}>
      <div>
        <h1 className="centered1" data-testid="hospital title">Hospitals</h1>
      </div >

      <div className="mt-2 mb-3 d-flex justify-content-around">
        <CustomDropdown title={sortBy} items={hospitalParams} onSelectItem={handleSortBy} />
        <CustomDropdown title={orderBy} items={orders} onSelectItem={handleOrderBy} />
        {/* <CustomDropdown title={selectedCity} items={cities} onSelectItem={handleCitySelect} /> */}
        {!customString && <SearchBar onSearch={handleSearch} />}
      </div>

      <Row style={{ marginBottom: '20px',}}>
        {hospitalData.slice(startIndex, endIndex).map((hospital, index) => (
 <Col key={index} md={3} style={{ width: "auto", margin:"auto" }}>
            <HospitalCard
              title={hospital.hospital_name}
              beds={hospital.beds}
              city={hospital.city}
              image={hospital.hospital_image_url}
              revenue={hospital.gross_patient_revenue}
              discharges={hospital.total_discharges}
              days={hospital.patient_days}
              link={`/Hospitals/${hospital.idhospital_info}`}
              highlighted={searchTerm}
            />
          </Col>
        ))}
      </Row>

      <div className="d-flex justify-content-center">
        <ReactPaginate
          previousLabel={'< previous'}
          nextLabel={'next >'}
          breakLabel={'...'}
          breakClassName={'break-me'}
          pageCount={totalPages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={'pagination'}
          subContainerClassName={'pages pagination'}
          activeClassName={'active'}
          previousLinkClassName={'page-link'}
          nextLinkClassName={'page-link'}
          pageClassName={'page-item'}
          pageLinkClassName={'page-link'}
        />
      </div>
      <div>
        <p className="d-flex justify-content-center" data-testid="hospital count">
          Showing {startIndex + 1} - {endIndex} of {totalItems} results
        </p>
      </div>
    </div >
  );
};

export default Hospitals;
