"use client";
import React from "react";
import {
  Container,
  SimpleGrid,
  Img,
  Flex,
  Heading,
  Text,
  Stack,
  StackDivider,
} from "@chakra-ui/react";
import colors from "./colors"

const Subset = ({ smalltext, text }) => {
  return (
    <Stack direction={"row"} align={"center"}>
      <Flex
        w={8}
        h={8}
        align={"center"}
        justify={"center"}
        rounded={"full"}
      >
      </Flex>
      <Text fontWeight={600}>{text}</Text>
      <Text fontWeight={200}>{smalltext}</Text>
    </Stack>
  );
};

export default function SplitWithImage() {
  return (
    <Container maxW={"5xl"} py={12}>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
        <Flex
          direction={{ base: "column", md: "row" }}
          justify="space-between"
          alignItems={{ base: "center", md: "flex-start" }}
        >
          <Stack spacing={5} flex="1">
            <Heading data-testid="home title">Elder Helper Texas</Heading>
            <Text
              textTransform={"uppercase"}
              fontWeight={600}
              alignSelf={"flex-start"}
              backgroundColor={colors.primary}
              style={{ borderRadius: 10, padding: 8 }}
              color="#1b5e20"
            >
              What We Do
            </Text>
            <Text>
              Welcome to ElderCareTexas, your dedicated platform committed to supporting seniors
              and their families with comprehensive resources and community connections across
              the Lone Star State. As your trusted ally in elder care, we provide a one-stop
              destination for accessing vital information, discovering local hospitals, engaging
              in community events, and fostering a network of support.



              At ElderCareTexas, we're committed to ensuring that every senior in Texas receives
              the care, support, and resources they deserve. Join us today and embark on a
              journey of empowered aging within our welcoming community.
            </Text>
            <Stack
              spacing={10}
              divider={
                <StackDivider
                />
              }
            >
              <Subset
                text={"Elderly Homes"}
                smalltext="400 Elderly Homes"
              />
              <Subset
                text={"Hospitals"}
                smalltext="370 Hospitals across Texas."
              />
              <Subset
                text={"Events"}
                smalltext="Over 500 events for seniors."
              />
            </Stack>
          </Stack>
          <Img
            alt={"cover image"}    //DOWNLOAD AND PUT IN DIFFERENT FOLDER IN FUTURE
            src={"https://media.istockphoto.com/id/497098531/photo/happy-senior-man-celebrating-success-on-white.jpg?s=612x612&w=0&k=20&c=4nlwQ__IEf0pQmaml69I0a4UEQ3EGKpHJnmDhlws644="}
            objectFit={"cover"}
            height={"auto"} // Set height to auto
            width={"auto"} // Set width to 100%
            ml={{ base: 0, md: 20 }} // Adjust ml to position the image
            mb={{ base: 8, md: 0 }} // Adjust mb for spacing between text and image
          />
        </Flex>
      </SimpleGrid>
    </Container>
  );
}
