import React from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import '../components/CardGroups.css'; // Import your CSS file
import '../components/ImageGrid.css'
import ImageCard from '../components/ImageCard';
import '../components/Heading.css'; // Import your CSS file
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS

import { useState, useEffect } from 'react';
import axios from 'axios';

const About = () => {
  const [contributionData, setContributionData] = useState(null);
  const [issueData, setIssueData] = useState(null);

  const projectId = 54659906;
  const API_TOKEN = process.env.REACT_APP_GITLAB_API_TOKEN;

  useEffect(() => {
    const fetchContributionData = async () => {
      try {
        const response = await axios.get(`https://gitlab.com/api/v4/projects/${projectId}/repository/contributors`, {
          headers: { 'PRIVATE-TOKEN': API_TOKEN }
        });
        setContributionData(response.data);
      } catch (error) {
        console.error('Error fetching contribution data:', error);
      }
    };

    const fetchIssueData = async () => {
      try {
        const response = await axios.get(`https://gitlab.com/api/v4/projects/${projectId}/issues?page=1&per_page=100&state=closed`, {
          headers: { 'PRIVATE-TOKEN': API_TOKEN }
        });
        setIssueData(response.data);
      } catch (error) {
        console.error('Error fetching issue data:', error);
      }
    }

    fetchContributionData();
    fetchIssueData();
  }, [projectId, API_TOKEN]);

  const getCommitsByEmail = (email) => {
    if (!contributionData) {
      return 0;
    }

    const totalCommits = contributionData
      .filter(contributor => contributor.email === email)
      .reduce((acc, contributor) => acc + contributor.commits, 0);

    return totalCommits;
  };

  const getIssuesByUsername = (username) => {
    if (!issueData) {
      return 0;
    }

    const totalIssues = issueData
      .filter(issue => issue.closed_by.username === username)
      .reduce((acc, issue) => acc + 1, 0);

    return totalIssues;
  };


  const imageArray = [
    // Add more image data as needed
    { src: 'https://upload.wikimedia.org/wikipedia/commons/thumb/c/c3/Python-logo-notext.svg/800px-Python-logo-notext.svg.png', alt: 'Image 1', logo: "Python" },
    { src: 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/React-icon.svg/1200px-React-icon.svg.png', alt: 'Image 2', logo: "React" },
    { src: 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b2/Bootstrap_logo.svg/800px-Bootstrap_logo.svg.png', alt: 'Image 3', logo: "Boostrap" },
    { src: 'https://www.vectorlogo.zone/logos/npmjs/npmjs-tile.svg', alt: 'Image 4', logo: "NPM Axios" },
    { src: 'https://yt3.googleusercontent.com/R6P5skGdZJeM1bebvt3ILeU8k-9tiqE5T198RmBH8SoGXH2gk_Lk-45uZoq6X6pW4a4c9Sqn=s900-c-k-c0x00ffffff-no-rj', alt: 'Image 5', logo: "GitLab" },
    { src: 'https://yt3.googleusercontent.com/X-rhKMndFm9hT9wIaJns1StBfGbFdLTkAROwm4UZ3n9ucrBky5CFIeeZhSszFXBgQjItzCD0SA=s900-c-k-c0x00ffffff-no-rj', alt: 'Image 5', logo: "Postman" },
    { src: 'https://miro.medium.com/v2/resize:fit:400/1*OARpkeBkn_Tw3vk8H769OQ.png', alt: 'Image 5', logo: "Docker" },
    { src: 'https://seeklogo.com/images/A/aws-amplify-logo-D68DDB5AB1-seeklogo.com.png', alt: 'Image 6', logo: "AWS Amplify" },
    { src: 'https://chris-ayers.com/assets/images/vscode-logo.png', alt: 'Image 7', logo: "VS Code" },
    { src: 'https://i0.wp.com/www.elearningworld.org/wp-content/uploads/2019/04/MySQL.svg.png?fit=600%2C400&ssl=1', alt: 'Image 7', logo: "MySQL" },
    { src: 'https://avatars.githubusercontent.com/u/54212428?s=280&v=4', alt: 'Image 7', logo: "Chakra UI" },
  ];

  const buttonStyle = {
    flex: 5,
    margin: '10px', // Adjust margin as needed
  };


  return (
    <div>
      <h1 className="centered1" data-testid="about title">About Us</h1>
      <h4 className="centered1">This website was created to help elderly people in the state of Texas find a place to live, services, and healthcare.
        As the elderly population skyrockets across the country, senior citizens are increasingly unaware of the communities, services, and healthcare centers near their location.
        This website will help bridge that gap and increase the elderly population’s well-being in Texas.</h4>
      <h4 className="centered1">Disparate data is a challenge for us because you first have to choose data you can easily access and then combine it with other pieces of data to paint a nice picture for the user.
        There was a plethora of data available to us, but we had to strategically think about how we could piece the puzzle together to create a well-represented website.
      </h4>
      <div className="card-group">
        <CardGroup>
          <div className="card">
            <Card>
              <Card.Img className="card-image" variant="top" src="https://miro.medium.com/v2/resize:fit:720/format:webp/0*RMXgTFiGbCq8XQvz.jpeg" />
              <Card.Body>
                <Card.Title>Adrian Gallardo</Card.Title>
                <Card.Text>
                  I'm a third-year CS major at UT Austin. I am graduating this spring to work at H-E-B as a SWE this summer.<br /><br />
                  Role: Backend <br />
                  Commits: {getCommitsByEmail("adrian.gallardo512@utexas.edu") + getCommitsByEmail("adrian.gallardo512@gmail.com")}
                  <br />
                  Issues: {getIssuesByUsername("adrian.gallardo512")}
                  <br />
                  Unit Tests: 22<br />
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
          <div className="card">
            <Card>
              <Card.Img className="card-image" variant="top" src="https://miro.medium.com/v2/resize:fit:720/format:webp/1*rGXXwOjXU05A5-beg-bexw.png" />
              <Card.Body>
                <Card.Title>Matthew Nguyen</Card.Title>
                <Card.Text>
                  I'm a CS major at UT Austin graduating spring 2025. I am interested in using technology make an impact in the world. <br /><br />
                  Role: Frontend<br />
                  Commits: {getCommitsByEmail("matthewnguyen2125@gmail.com")}<br />
                  Issues: {getIssuesByUsername("NguyenMatthew")}<br />
                  Unit Tests: 0 <br />
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
          <div className="card">
            <Card>
              <Card.Img className="card-image" variant="top" src="https://miro.medium.com/v2/resize:fit:720/format:webp/1*u-Wrjg4fTIW02aL-A7U06g.jpeg" />
              <Card.Body>
                <Card.Title>Yuvraj Sahu</Card.Title>
                <Card.Text>
                  I'm a Computer Science + Business dual major at UT Austin. I'm into skiing, reading, and solving puzzles! <br /><br />
                  Role: Frontend<br />
                  Commits: {getCommitsByEmail("ysahu.atx@gmail.com")}<br />
                  Issues: {getIssuesByUsername("ysahu.atx")}<br />
                  Unit Tests: 34 <br />
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </CardGroup>
      </div>
      <div className="card-group">
        <CardGroup>
          <div className="card">
            <Card>
              <Card.Img className="card-image" variant="top" src="https://miro.medium.com/v2/resize:fit:720/format:webp/1*_u0lkVxTQhS9Uepfx0crew.jpeg" />
              <Card.Body>
                <Card.Title>Jose Monroy-Soto</Card.Title>
                <Card.Text>
                  Third year CS Student with an interest in machine learning. I'm into playing videogames and hanging out with friends.<br /><br />
                  Role: Frontend <br />
                  Commits: {getCommitsByEmail("josemonroysoto@utexas.edu")}<br />
                  Issues: {getIssuesByUsername("josemonroysoto")}<br />
                  Unit Tests: 0<br />
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
          <div className="card">
            <Card>
              <Card.Img className="card-image" variant="top" src="https://miro.medium.com/v2/resize:fit:720/format:webp/1*HiW9jeUc1etQzymWF5hoPg.jpeg" />
              <Card.Body>
                <Card.Title>Sidharth Srinivas</Card.Title>
                <Card.Text>
                  I am a junior studying Computer Science looking to be a software engineer out college. I am interested in robotics, fitness, and traveling!<br /> <br />
                  Role: Frontend<br />
                  Commits: {getCommitsByEmail("kbsidsri@gmail.com")}<br />
                  Issues: {getIssuesByUsername("kbsidsri")}<br />
                  Unit Tests: 20<br />
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </CardGroup>
      </div>
      <div>
        <h1 className='centered1'>Total Stats</h1>
        <h2 className='centered1' data-testid="commit count">Total Commits: {contributionData ? contributionData.reduce((acc, contributor) => acc + contributor.commits, 0) : 0}</h2>
        <h2 className='centered1'>Total Issues: {issueData ? issueData.length : 0}</h2>
      </div>
      <h1 className="centered1">Tools Used</h1>
      <div className="image-grid">
        {imageArray.map((image, index) => (
          <ImageCard key={index} src={image.src} alt={image.alt} textBelowImage={image.logo} />
        ))}
      </div><br /> <br /> <br />
      <h1 className="centered1">Data Sources</h1>
      <h4 className="centered1"><a href="https://developers.google.com/maps">Google Maps Platform</a> <br></br>
        <a href="https://www.eventbrite.com/d/united-states--texas/senior-events/">Eventbrite</a> <br></br>
        <a href="https://www.ahd.com/states/hospital_TX.html">American Hospital Directory</a> <br></br>
      </h4>
      <div className="text-center mt-5">
        <Button variant="primary" size="xl" style={{ ...buttonStyle, padding: '50px', fontSize: '20px' }} href="https://gitlab.com/ysahu.atx/cs373-group-23" target="_blank">GitLab Repository</Button>{' '}
        <Button variant="danger" size="xl" style={{ ...buttonStyle, padding: '50px', fontSize: '20px' }} href="https://documenter.getpostman.com/view/17862950/2sA2r545M3" target="_blank">Postman Documentation</Button>{' '}
      </div>
      <br></br>
      <h1 className="centered1">PechaKucha Presentation</h1>
      <div className="text-center">
  <iframe
    width="640"
    height="480"
    src="https://www.youtube.com/embed/DfO972UN7Pc"
    title="PechaKucha Presentation"
    frameBorder="0"
    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
  ></iframe>
</div>
    </div>
  );
}

export default About;