import React, { useState, useEffect, useRef } from 'react';
import * as d3 from 'd3';
import axios from 'axios';

const BarGraph = () => {
  const chartRef = useRef();
  const width = 1300;
  const height = 500;
  const margin = { top: 30, right: 40, bottom: 110, left: 80 };
  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;

  // const url = `https://cs373backend.elderhelpertexas.me/events/`;
  const url = `https://cs373-backend.literatetx.me/get_programs`;
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(url);
        setData(response.data.result);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (chartRef.current && data.length) {
      drawChart();
    }
    // eslint-disable-next-line
  }, [data]);

  const drawChart = () => {
    d3.select(chartRef.current).selectAll('svg').remove();

    const counties = data.map(program => program.county).filter(county => county != null);

    const countyCounts = counties.reduce((acc, county) => {
      acc[county] = (acc[county] || 0) + 1;
      return acc;
    }, {});

    // Initialize 'Other' category
    let otherCount = 0;

    // Filter out counties with fewer than 5 occurrences and add them to 'Other'
    for (const county in countyCounts) {
      if (countyCounts[county] < 2) {
        otherCount += countyCounts[county];
        delete countyCounts[county];
      }
    }

    // Add 'Other' category to the data if necessary
    if (otherCount > 0) {
      countyCounts['Other'] = otherCount;
    }

    // Convert categoryCounts to an array of objects for bar graph
    const barData = Object.keys(countyCounts).map(county => ({
      county,
      value: countyCounts[county]
    }));

    // Set up the scales
    const xScale = d3.scaleBand()
      .domain(barData.map(d => d.county))
      .range([0, innerWidth])
      .padding(0.1);

    const yScale = d3.scaleLinear()
      .domain([0, d3.max(barData, d => d.value)])
      .nice()
      .range([innerHeight, 0]);

    // Create an SVG element
    const svg = d3.select(chartRef.current)
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', `translate(${margin.left}, ${margin.top})`);

    // Create bars with animation
    svg.selectAll('rect')
      .data(barData)
      .enter()
      .append('rect')
      .attr('x', d => xScale(d.county))
      .attr('y', innerHeight) // Start the bars at the bottom of the graph
      .attr('width', xScale.bandwidth())
      .attr('height', 0) // Initially set the height to 0
      .attr('fill', 'green')
      .transition() // Add transition for the bars
      .duration(1000) // Animation duration
      .attr('y', d => yScale(d.value))
      .attr('height', d => innerHeight - yScale(d.value));

    // Add mouseover interaction
    svg.selectAll('rect')
      .on('mouseover', function () {
        d3.select(this).attr('fill', 'lightgreen');
      })
      .on('mouseout', function () {
        d3.select(this).attr('fill', 'green');
      });

    // Add x-axis
    svg.append('g')
      .attr('transform', `translate(0, ${innerHeight})`)
      .call(d3.axisBottom(xScale))
      .selectAll('text')
      .style('text-anchor', 'end')
      .attr('dx', '-.8em')
      .attr('dy', '.15em')
      .attr('transform', 'rotate(-45)')
      .style('font-weight', 'bold') // Set font weight to bold for x-axis labels
      .style('font-size', '11px');

    // Add y-axis
    svg.append('g')
      .call(d3.axisLeft(yScale));

    // Add x-axis label
    svg.append('text')
      .attr('x', innerWidth / 2)
      .attr('y', innerHeight + margin.top + 70)
      .attr('text-anchor', 'middle')
      .text('Counties')
      .style('font-weight', 'bold')
      .style('font-size', '24px');

    // Add y-axis label
    svg.append('text')
      .attr('transform', `translate(${-margin.left + 20},${innerHeight / 2}) rotate(-90)`)
      .attr('text-anchor', 'middle')
      .text('Programs')
      .style('font-weight', 'bold')
      .style('font-size', '24px');
  };

  return <div ref={chartRef}></div>;
};

export default BarGraph;

