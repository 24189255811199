import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';


const HospitalCardAxios = ({ id }) => {

    const [hospitalData, setHospitalData] = useState({});

    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.get(`https://cs373backend.elderhelpertexas.me/hospitals/${id}`);
          setHospitalData(response.data);
          console.log(response);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
  
      fetchData();
    }, [id]);

  
  return (
    <Card style={{ width: '19rem', margin: '0 auto', marginBottom: '20px', height: "40em", marginLeft: '5px', marginRight: '5px', border: '1px solid black'}}>
        {hospitalData.hospital_image_url && (
    <Card.Img
      variant="top"
      src={hospitalData.hospital_image_url}
      alt={hospitalData.hospital_name}
      style={{ width: '100%', height: '300px', objectFit: 'cover' }}
    />
  )}
      <Card.Body>
        <Card.Title>{hospitalData.hospital_name}</Card.Title>
        <Card.Text><b>City: </b>{hospitalData.city}</Card.Text>
        <Card.Text><b>Beds: </b>{hospitalData.beds}</Card.Text>
        <Card.Text><b>Gross Patient Revenue: </b>{hospitalData.gross_patient_revenue}</Card.Text>
        <Card.Text><b>Patient Days: </b>{hospitalData.patient_days}</Card.Text>
        <Card.Text><b>Total Discharges: </b>{hospitalData.total_discharges}</Card.Text>
      </Card.Body>
      <div style={{ alignSelf: 'flex-start', marginBottom: '20px', marginLeft: '10px' }}>
          <Link to={`/Hospitals/${hospitalData.idhospital_info}`}>
            <Button variant="primary">Learn More</Button>
          </Link>
    </div>
    </Card>
  );
};

export default HospitalCardAxios;
