import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import InstanceTemplate from "./InstanceTemplate";
import EventsCardAxios from './EventsCardAxios';
import ElderHomeCardAxios from "./ElderHomeCardAxios";

const HospitalInstance = () => {
  const {id} = useParams();
  const url = `https://cs373backend.elderhelpertexas.me/hospitals/${id}`;
  const [info, setInfo] = useState({dataObject: null});

  useEffect(() => {
    const retrieve = async () => {
      const response = await axios.get(url);
      setInfo({dataObject: response.data});
    };
    retrieve();
  }, [url]);

  const data = info.dataObject;
  if (data == null) return (<div></div>);

  const {
    beds, city, gross_patient_revenue, hospital_image_url, hospital_name,
    patient_days, related_events, related_homes, total_discharges
  } = data;
  
  return (
    <InstanceTemplate
      title={hospital_name}
      content={
        `${hospital_name} is located in ${city}, Texas. It contains \
        ${beds} beds, and has had a total of ${total_discharges} \
        discharges. The total number of patient days is ${patient_days}, \
        and the total gross patient revenue for this hospital is \
        ${gross_patient_revenue}.`
      }
      image={hospital_image_url}
      location={`${hospital_name}, ${city}, Texas`}
      summary={{
        'City': city,
        'Beds': beds,
        'Gross Patient Revenue': gross_patient_revenue,
        'Patient Days': patient_days,
        'Total Discharges': total_discharges,
      }}
      relatedDetails1={{
        name: 'Events',
        data: related_events,
        AxiosCardType: EventsCardAxios
      }}
      relatedDetails2={{
        name: 'Elderly Homes',
        data: related_homes,
        AxiosCardType: ElderHomeCardAxios
      }}
    />
  );
}

export default HospitalInstance;
