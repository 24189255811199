import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';

const EventCardAxios = ({ id }) => {
  const [hospitalData, setHospitalData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://cs373backend.elderhelpertexas.me/events/${id}`);
        setHospitalData(response.data);
        console.log(response);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [id]);

  return (
<Card style={{ width: '19rem', margin: '0 auto', marginBottom: '20px', height: '38em', display: 'flex', flexDirection: 'column', marginLeft: '5px', marginRight: '5px', border: '1px solid black'}}>
  {hospitalData.event_img_src && (
    <Card.Img
      variant="top"
      src={hospitalData.event_img_src}
      alt={hospitalData.title}
      style={{ width: '100%', height: '300px', objectFit: 'cover' }}
    />
  )}
  <Card.Body style={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
    <Card.Title>{hospitalData.title}</Card.Title>
    <Card.Text><b>City: </b>{hospitalData.event_city}</Card.Text>
    <Card.Text><b>Category: </b>{hospitalData.event_category}</Card.Text>
    <Card.Text><b>Price: </b>{hospitalData.event_paid_status}</Card.Text>
    <Card.Text><b>Date: </b>{hospitalData.event_time}</Card.Text>
    <Card.Text><b>Location: </b>{hospitalData.event_location && (
      <Card.Link href={`https://www.google.com/maps?q=${encodeURIComponent(hospitalData.event_location)}`} target="_blank">
        {hospitalData.event_location}
      </Card.Link>
    )}</Card.Text>
  </Card.Body>
  <div style={{ alignSelf: 'flex-start', marginBottom: '20px', marginLeft: '10px' }}>
    <Link to={`/Events/${hospitalData.idevent_info}`}>
      <Button variant="primary">Learn More</Button>
    </Link>
  </div>
</Card>
  );
};

export default EventCardAxios;
