import React from 'react';
import SplitWithImage from '../HomepageTop';
import IndividualIntervalsExample from '../Slideshow';
import { Box } from "@chakra-ui/react";

const Home = () => {
    return (
        <>
            <Box minHeight="0vh">
                <div className="container">
                    <SplitWithImage />
                </div>
            </Box>
            <Box backgroundColor="#a4eaa7" minHeight="00vh">
                    <IndividualIntervalsExample />
            </Box>
        </>
    );
}

export default Home;
