import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';


  const NursingHomeCardAxios = ({ id }) => {
    const [hospitalData, setHospitalData] = useState({});

    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.get(`https://cs373backend.elderhelpertexas.me/elderhomes/${id}`);
          setHospitalData(response.data);
          console.log(response);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
  
      fetchData();
    }, [id]);


    //get website title
    function extractSubdomain(url) {
      // Define a regular expression to match the substring before the top-level domain
      const substringRegex = /^(https?:\/\/)?(www\.)?(.*?)(\.com|\.org|\.net|\.edu|\.gov|\.mil|\.co\.uk|\.io|\.ai|$)/;
  
      // Use the regular expression to extract the substring
      const matches = url.match(substringRegex);
  
      // Check if a match is found
      if (matches && matches.length >= 4) {
          // Extracted substring is in the third capturing group
          return matches[3];
      } else {
          // Return an empty string if no match is found
          return '';
      }
  }
  
  
  
    return (
<Card style={{ width: '19rem', margin: '0 auto', marginBottom: '20px', height: '40em', display: 'flex', flexDirection: 'column' , marginLeft: '5px', marginRight: '5px', border: '1px solid black' }}>
  {hospitalData.image_url && (
    <Card.Img
      variant="top"
      src={hospitalData.image_url}
      alt={hospitalData.display_name}
      style={{ width: '100%', height: '300px', objectFit: 'cover' }}
    />
  )}
        <Card.Body>
          <Card.Title>{hospitalData.display_name}</Card.Title>
          <Card.Text>
    <b>Website: </b>
    <span style={{ display: 'inline' }}>
  {hospitalData.website_url && (
    <Card.Link href={hospitalData.website_url}>
      {extractSubdomain(hospitalData.website_url)}
    </Card.Link>
  )}
</span>
  </Card.Text>
          <Card.Text><b>City:</b> {hospitalData.city}</Card.Text>
          <Card.Text><b>Phone Number:</b> {hospitalData.national_phone_number}</Card.Text>
          <Card.Text><b>Rating: </b>{hospitalData.rating}</Card.Text>
          <b>Location: </b>{hospitalData.formatted_address && (
            <Card.Link href={`https://www.google.com/maps?q=${encodeURIComponent(hospitalData.formatted_address)}`} target="_blank">
              {hospitalData.formatted_address}
            </Card.Link>
          )}
        </Card.Body>
        <div style={{ alignSelf: 'flex-start', marginBottom: '20px', marginLeft: '10px' }}>
        <Link to={`/ElderlyHomes/${hospitalData.idelder_home_info}`}>
          <Button variant="primary">Learn More</Button>
        </Link>
      </div>
      </Card>
    );
  };

export default NursingHomeCardAxios;
