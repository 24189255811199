import React, { useState, useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactPaginate from 'react-paginate';
import axios from 'axios';
import SocialCard from '../components/SocialCard'; // Import the Social Card component
import SearchBar from '../components/SearchBar';
import CustomDropdown from '../components/CustomDropdown';


const Events = ( {customString}) => {
  const [socialData, setSocialData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const itemsPerPage = 12; // Define how many items you want per page
  const [currentPage, setCurrentPage] = useState(0);
  const [sortBy, setSortBy] = useState("Name");
  const [orderBy, setOrderBy] = useState("Ascending");
  const params = ["Name", "City", "Category", "Price", "Date", "Location"];
  const orders = ["Ascending", "Descending"];

  useEffect(() => {
    const getSortParamForApi = () => {
      const apiMapping = {
        "Name": "eventtitle",
        "City": "eventcity",
        "Category": "eventcategory",
        "Price": "eventpaidstatus",
        "Date": "eventtime",
        "Location": "eventlocation",
      };

      const apiParam = apiMapping[sortBy] || 'eventtitle';
      const mapOrderToParam = (order) => order === "Ascending" ? "asc" : "desc";
      const orderSuffix = mapOrderToParam(orderBy);
      return `${apiParam}${orderSuffix}`;
    };

    const fetchData = async () => {
      try {
        let url = 'https://cs373backend.elderhelpertexas.me/events/';
        if (customString && customString.trim() !== '') {
          url += `?search=${customString}`;
          handleSearch2(customString);
        } else if (searchTerm) {
          url += `?search=${searchTerm}`;
        }
        
        const sortParam = getSortParamForApi();
        if (searchTerm || customString) {
          url += `&sort_order=${sortParam}`;
        } else {
          url += `?sort_order=${sortParam}`;
        }
        
        console.log(url);
        const response = await axios.get(url);
        setSocialData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
        setSocialData([]);
      }
    };
  
    fetchData();
  }, [customString, searchTerm, sortBy, orderBy, currentPage]);

  const totalItems = socialData.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const startIndex = currentPage * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, totalItems);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  const handleSortBy = (item) => {
    console.log(item);
    setSortBy(item);
    setCurrentPage(0);
  };

  const handleOrderBy = (order) => {
    setOrderBy(order);
    setCurrentPage(0);

  };

  const handleSearch = (searchTerm) => {
    if (customString){
      setSearchTerm(customString);
    }else{
      setSearchTerm(searchTerm);
    }
    setCurrentPage(0); // Reset to the first page when searching
  };

  const handleSearch2 = (searchTerm) => {
    if (customString){
      setSearchTerm(customString);
    }else{
      setSearchTerm(searchTerm);
    } // Reset to the first page when searching
  };

  return (
<div style={{ width: '100%', paddingRight: '15px', paddingLeft: '15px', marginRight: 'auto', marginLeft: 'auto' }}>
      <div>
        <h1 className="centered1" data-testid="event title">Events</h1>
      </div>

      <div className="mt-2 mb-3 d-flex justify-content-around">
        <CustomDropdown title={sortBy} items={params} onSelectItem={handleSortBy} />
        <CustomDropdown title={orderBy} items={orders} onSelectItem={handleOrderBy} />
        {!customString && <SearchBar onSearch={handleSearch} />}
      </div>

      <Row style={{ marginBottom: '20px' }}>
        {socialData.slice(startIndex, endIndex).map((social, index) => (
 <Col key={index} md={3} style={{ width: "auto", margin:"auto" }}>
            <SocialCard
              title={social.event_title}
              image={social.event_img_src}
              price={social.event_paid_status}
              location={social.event_location}
              date={social.event_time}
              host={social.event_category}
              city={social.event_city}
              link={`/Events/${social.idevent_info}`}
              highlighted={searchTerm}
            // title, content, image, price, host, date, location, link
            />
          </Col>
        ))}
      </Row>

      <div className="d-flex justify-content-center">
        <ReactPaginate
          previousLabel={'< previous'}
          nextLabel={'next >'}
          breakLabel={'...'}
          breakClassName={'break-me'}
          pageCount={totalPages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={'pagination'}
          subContainerClassName={'pages pagination'}
          activeClassName={'active'}
          previousLinkClassName={'page-link'}
          nextLinkClassName={'page-link'}
          pageClassName={'page-item'}
          pageLinkClassName={'page-link'}
        />
      </div>
      <div>
        <p className="d-flex justify-content-center" data-testid="event count">
          Showing {startIndex + 1} - {endIndex} of {totalItems} results
        </p>
      </div>
    </div>
  );
};

export default Events;
