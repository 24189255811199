// CardComponent.jsx

import React from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';

const NursingHomeCard = ({ title, content, phone, type, image, location, rating, website, link, city, highlighted }) => {
  //get website title
  function extractSubdomain(url) {
    // Define a regular expression to match the substring before the top-level domain
    const substringRegex = /^(https?:\/\/)?(www\.)?(.*?)(\.com|\.org|\.net|\.edu|\.gov|\.mil|\.co\.uk|\.io|\.ai|$)/;

    // Use the regular expression to extract the substring
    const matches = url.match(substringRegex);

    // Check if a match is found
    if (matches && matches.length >= 4) {
        // Extracted substring is in the third capturing group
        return matches[3];
    } else {
        // Return an empty string if no match is found
        return '';
    }
}


  const highlightText = (text, highlighted) => {
    if (!highlighted) return text;
    const escapedHighlighted = highlighted.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const regex = new RegExp(`(${escapedHighlighted})`, 'gi');
    const parts = text.split(regex);

    return parts.map((part, index) => {
      if (part.toLowerCase() === highlighted.toLowerCase()) {
        return <span key={index} style={{ backgroundColor: 'yellow' }}>{part}</span>;
      }
      return part;
    });
  };
  return (
    <Card style={{ width: '20rem', margin: '0 auto', marginBottom: '20px', height: "40em", border: '1px solid black'}}>
      {image && <Card.Img variant="top" src={image} alt={title} style={{ width:  '100%', height: '300px', objectFit: 'cover' }} />}
      <Card.Body>
        <Card.Title>{highlightText(title, highlighted)}</Card.Title>
        <Card.Text>
  <b>Website: </b>
  <span style={{ display: 'inline' }}>
    <Card.Link href={website}>{highlightText(extractSubdomain(website), highlighted)}</Card.Link>
  </span>
</Card.Text>
        <Card.Text><b>City:</b> {highlightText(city, highlighted)}</Card.Text>
        <Card.Text><b>Phone Number:</b> {highlightText(phone.toString(), highlighted)}</Card.Text>
        <Card.Text>{content}</Card.Text>
        <Card.Text><b>Rating: </b>{highlightText(rating.toString(), highlighted)}</Card.Text>
        <b>Location: </b>{location && (
          <Card.Link href={`https://www.google.com/maps?q=${encodeURIComponent(location)}`} target="_blank">
            {highlightText(location, highlighted)}
          </Card.Link>
        )}
      </Card.Body>
      <div style={{ alignSelf: 'flex-start', marginBottom: '20px', marginLeft: '10px' }}>
      <Link to={link}>
        <Button variant="primary">Learn More</Button>
      </Link>
    </div>
    </Card>
  );
};

export default NursingHomeCard;
