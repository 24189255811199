import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import HospitalCard from '../components/HospitalCard';
import NursingHomeCard from '../components/NursingHomeCard';
import SocialCard from '../components/SocialCard';
import ReactPaginate from 'react-paginate';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const SearchResults = () => {
    const { searchTerm } = useParams();
    const [hospitalResults, setHospitalResults] = useState([]);
    const [nursingHomeResults, setNursingHomeResults] = useState([]);
    const [eventResults, setEventResults] = useState([]);
    const itemsPerPage = 12; // Define how many items you want per page
    const [currentPage, setCurrentPage] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const hospitalResponse = await axios.get(`https://cs373backend.elderhelpertexas.me/hospitals/?search=${searchTerm}`);
                setHospitalResults(hospitalResponse.data);

                const elderhomesResponse = await axios.get(`https://cs373backend.elderhelpertexas.me/elderhomes/?search=${searchTerm}`);
                setNursingHomeResults(elderhomesResponse.data);

                const socialResponse = await axios.get(`https://cs373backend.elderhelpertexas.me/events/?search=${searchTerm}`);
                setEventResults(socialResponse.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [searchTerm]);

    const totalItems = hospitalResults.length + nursingHomeResults.length + eventResults.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    const startIndex = currentPage * itemsPerPage;
    const endIndex = Math.min(startIndex + itemsPerPage, totalItems);

    const handlePageClick = ({ selected }) => {
        setCurrentPage(selected);
    };

    const combinedResults = [
        ...hospitalResults.map(hospital => ({ type: 'hospital', ...hospital })),
        ...nursingHomeResults.map(nursingHome => ({ type: 'nursing_home', ...nursingHome })),
        ...eventResults.map(event => ({ type: 'event', ...event }))
    ];

    return (
        <div>
            <Row>
                {combinedResults.slice(startIndex, endIndex).map((result, index) => (
                    <Col key={index} md={3}>
                        {result.type === 'hospital' && (
                            <HospitalCard
                                title={result.hospital_name}
                                beds={result.beds}
                                city={result.city}
                                image={result.hospital_image_url}
                                revenue={result.gross_patient_revenue}
                                discharges={result.total_discharges}
                                days={result.patient_days}
                                link={`/Hospitals/${result.idhospital_info}`}
                                highlighted={searchTerm}
                            />
                        )}
                        {result.type === 'nursing_home' && (
                            <NursingHomeCard
                                title={result.display_name}
                                phone={result.national_phone_number}
                                website={result.website_url}
                                image={result.image_url}
                                location={result.formatted_address}
                                rating={result.rating}
                                city={result.city}
                                link={`/ElderlyHomes/${result.idelder_home_info}`}
                                highlighted={searchTerm}
                            />
                        )}
                        {result.type === 'event' && (
                            <SocialCard
                                title={result.event_title}
                                image={result.event_img_src}
                                price={result.event_paid_status}
                                location={result.event_location}
                                date={result.event_time}
                                host={result.event_category}
                                city={result.event_city}
                                link={`/Events/${result.idevent_info}`}
                                highlighted={searchTerm}
                            />
                        )}
                    </Col>
                ))}
            </Row>

            <Row className="justify-content-center">
                <Col>
                    <ReactPaginate
                        previousLabel={'< previous'}
                        nextLabel={'next >'}
                        breakLabel={'...'}
                        pageCount={totalPages}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageClick}
                        containerClassName={'pagination justify-content-center'}
                        subContainerClassName={'pages pagination'}
                        activeClassName={'active'}
                        previousLinkClassName={'page-link'}
                        nextLinkClassName={'page-link'}
                        pageClassName={'page-item'}
                        pageLinkClassName={'page-link'}
                    />
                </Col>
            </Row>

            <div>
                <p className="d-flex justify-content-center">
                    Showing {startIndex + 1} - {endIndex} of {totalItems} results
                </p>
            </div>
        </div>
    );
};

export default SearchResults;
