import React from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';


const HospitalCard = ({ title, city, beds, image, revenue, discharges, days, link, highlighted }) => {
  const highlightText = (text, highlighted) => {
    if (!highlighted) return text;
    const escapedHighlighted = highlighted.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const regex = new RegExp(`(${escapedHighlighted})`, 'gi');
    const parts = text.split(regex);

    return parts.map((part, index) => {
      if (part.toLowerCase() === highlighted.toLowerCase()) {
        return <span key={index} style={{ backgroundColor: 'yellow' }}>{part}</span>;
      }
      return part;
    });
  };

  return (
    <Card style={{ width: '20rem', margin: 'auto', marginBottom: '40px', height: "40em", border: '1px solid black'}}>
      {image && <Card.Img variant="top" src={image} alt={title} style={{ width:  '100%', height: '300px', objectFit: 'cover' }} />}
      <Card.Body>
        <Card.Title>{highlightText(title, highlighted)}</Card.Title>
        <Card.Text><b>City: </b>{highlightText(city, highlighted)}</Card.Text>
        <Card.Text><b>Beds: </b>{highlightText(beds, highlighted)}</Card.Text>
        <Card.Text><b>Gross Patient Revenue: </b>{highlightText(revenue, highlighted)}</Card.Text>
        <Card.Text><b>Patient Days: </b>{highlightText(days, highlighted)}</Card.Text>
        <Card.Text><b>Total Discharges: </b>{highlightText(discharges, highlighted)}</Card.Text>
      </Card.Body>
      <div style={{ alignSelf: 'flex-start', marginBottom: '20px', marginLeft: '10px' }}>
      {link && (
          <Link to={link}>
            <Button variant="primary">Learn More</Button>
          </Link>
        )}
    </div>
    </Card>
  );
};

export default HospitalCard;
