// GoogleMaps.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY; //stores the value in the .env file
const GoogleMaps = ({ address, google }) => {
  const [coordinates, setCoordinates] = useState({ latitude: null, longitude: null });

  useEffect(() => {
    const geocodeAddress = async () => {
      try {
        const response = await axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
            address
          )}&key=${GOOGLE_MAPS_API_KEY}`
        );

        const { results } = response.data;
          // console.log("API address is ", GOOGLE_MAPS_API_KEY)
        if (results.length > 0) {
          const { lat, lng } = results[0].geometry.location;
          setCoordinates({ latitude: lat, longitude: lng });
        } else {
          setCoordinates({ latitude: null, longitude: null });
        }
      } catch (error) {
        console.error('Error geocoding address:', error.message);
      }
    };

    geocodeAddress();
  }, [address]);

  const mapStyles = {
    width: '100%',
    height: '100%',
  };

  const containerStyles = {
    position: 'relative',
    width: '800px',
    height: '700px',
    margin: '0px auto', // 'auto' for horizontal centering
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  return (
    <div style={containerStyles}>
      {coordinates.latitude !== null && coordinates.longitude !== null && (
        <div style={{ position: 'absolute', width: '100%', height: '100%' }}>
          {/* Google Maps using google-maps-react library */}
          <Map
            google={google}
            initialCenter={{ lat: coordinates.latitude, lng: coordinates.longitude }}
            center={{ lat: coordinates.latitude, lng: coordinates.longitude }}
            zoom={15}
            style={mapStyles}
          >
            <Marker position={{ lat: coordinates.latitude, lng: coordinates.longitude }} />
          </Map>
        </div>
      )}
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: GOOGLE_MAPS_API_KEY, // Replace with your actual API key
})(GoogleMaps);
