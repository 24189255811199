import React, { useState, useEffect, useRef } from 'react';
import * as d3 from 'd3';
import axios from 'axios';

const ScatterPlot = () => {
  const chartRef = useRef();
  const width = 1000;
  const height = 500;
  const margin = { top: 30, right: 40, bottom: 110, left: 80 };
  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;

  const url = `https://cs373backend.elderhelpertexas.me/elderhomes/`;
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(url);
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (chartRef.current && data.length) {
      drawChart();
    }
    // eslint-disable-next-line
  }, [data]);

  const drawChart = () => {
    d3.select(chartRef.current).selectAll('svg').remove();
  
    // Set up the scales
    const xScale = d3.scaleBand()
      .domain(data.map(d => d.city))
      .range([0, innerWidth])
      .padding(0.1);
  
    const yScale = d3.scaleLinear()
      .domain([0, d3.max(data, d => d.rating)])
      .nice()
      .range([innerHeight, 0]);

    // Define color scale
    const colorScale = d3.scaleOrdinal(d3.schemeCategory10)
      .domain(data.map(d => d.city));

    // Create an SVG element
    const svg = d3.select(chartRef.current)
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', `translate(${margin.left}, ${margin.top})`);
  
    // Create circles for scatter plot
    svg.selectAll('circle')
      .data(data)
      .enter()
      .append('circle')
      .attr('cx', d => xScale(d.city) + xScale.bandwidth() / 2 + Math.random() * 10 - 5) // Add randomness to x position
      .attr('cy', d => yScale(d.rating) + Math.random() * 20 - 10) // Add randomness to y position
      .attr('r', 5)
      .attr('fill', d => colorScale(d.city)) // Use color scale
      .on('mouseover', function(event, d) {
        d3.select(this).attr('r', 8); // Enlarge the circle on hover
        // Show tooltip
        // const tooltip = svg.append('text')
        //   .attr('class', 'tooltip')
        //   .attr('x', xScale(d.city) + xScale.bandwidth() / 2)
        //   .attr('y', yScale(d.rating) - 10)
        //   .attr('text-anchor', 'middle')
        //   .text(`${d.city}: ${d.rating}`)
        //   .style('font-size', '12px');
      })
      .on('mouseout', function(event, d) {
        d3.select(this).attr('r', 5); // Restore circle size
        // Hide tooltip
        svg.select('.tooltip').remove();
      });

    // Add x-axis
    svg.append('g')
      .attr('transform', `translate(0, ${innerHeight})`)
      .call(d3.axisBottom(xScale))
      .selectAll('text')
      .style('text-anchor', 'end')
      .attr('dx', '-.8em')
      .attr('dy', '.15em')
      .attr('transform', 'rotate(-45)')
      .style('font-weight', 'bold')
      .style('font-size', '11px');
  
    // Add y-axis
    svg.append('g')
      .call(d3.axisLeft(yScale));
  
    // Add x-axis label
    svg.append('text')
      .attr('x', innerWidth / 2)
      .attr('y', innerHeight + margin.top + 70)
      .attr('text-anchor', 'middle')
      .text('Cities')
      .style('font-weight', 'bold')
      .style('font-size', '24px');
  
    // Add y-axis label
    svg.append('text')
      .attr('transform', `translate(${-margin.left + 20},${innerHeight / 2}) rotate(-90)`)
      .attr('text-anchor', 'middle')
      .text('Rating')
      .style('font-weight', 'bold')
      .style('font-size', '24px');
  };

  return <div ref={chartRef}></div>;
};

export default ScatterPlot;
