// CardComponent.jsx

import React from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';

const SocialCard = ({ title, image, price, host, date, location, link, city, highlighted }) => {
  const highlightText = (text, highlighted) => {
    if (!highlighted) return text;
    const escapedHighlighted = highlighted.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const regex = new RegExp(`(${escapedHighlighted})`, 'gi');
    const parts = text.split(regex);

    return parts.map((part, index) => {
      if (part.toLowerCase() === highlighted.toLowerCase()) {
        return <span key={index} style={{ backgroundColor: 'yellow' }}>{part}</span>;
      }
      return part;
    });
  };
  return (
    <Card style={{ width: '20rem', margin: '0 auto', marginBottom: '20px', height: '38em', display: 'flex', flexDirection: 'column', border: '1px solid black' }}>
    {image && <Card.Img variant="top" src={image} alt={title} style={{ width: '100%', height: '300px', objectFit: 'cover' }} />}
    <Card.Body style={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
        <Card.Title>{highlightText(title, highlighted)}</Card.Title>
        <Card.Text><b>City: </b>{highlightText(city, highlighted)}</Card.Text>
        <Card.Text><b>Category: </b>{highlightText(host, highlighted)}</Card.Text>
        <Card.Text><b>Price: </b>{highlightText(price, highlighted)}</Card.Text>
        <Card.Text><b>Date: </b>{highlightText(date, highlighted)}</Card.Text>
        <Card.Text><b>Location: </b>{location && (
          <Card.Link href={`https://www.google.com/maps?q=${encodeURIComponent(location)}`} target="_blank">
            {highlightText(location, highlighted)}
          </Card.Link>
        )}</Card.Text>
      </Card.Body>
      <div style={{ alignSelf: 'flex-start', marginBottom: '20px', marginLeft: '10px' }}>
      <Link to={link}>
        <Button variant="primary">Learn More</Button>
      </Link>
    </div>
    </Card>
  );
};

export default SocialCard;
