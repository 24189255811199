import React, { useState, useEffect, useRef } from 'react';
import * as d3 from 'd3';
import axios from 'axios';

const BubblePlot = () => {
  const chartRef = useRef();
  const width = 1000;
  const height = 500;
  const margin = { top: 30, right: 40, bottom: 60, left: 60 }; // Adjusted bottom margin for x-axis labels
  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;
  const legendWidth = 200;
  const legendHeight = 150; // Increased height for the legend

  const url = `https://cs373-backend.literatetx.me/get_counties`;
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(url);
        setData(response.data.result);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (chartRef.current && data.length) {
      drawChart();
    }
    // eslint-disable-next-line
  }, [data]);

  const drawChart = () => {
    d3.select(chartRef.current).selectAll('svg').remove();

    const radiusScale = d3.scaleSqrt()
      .domain([0, d3.max(data, d => d.population)])
      .range([2, 20]);

    const svg = d3.select(chartRef.current)
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', `translate(${margin.left}, ${margin.top})`);

    // Y-axis (dependent on percent_literacy_lacking)
    const yScale = d3.scaleLinear()
      .domain([0, d3.max(data, d => d.percent_literacy_lacking)])
      .range([innerHeight, 0]);

    svg.append('g')
      .call(d3.axisLeft(yScale));

    // Y-axis label
    svg.append('text')
      .attr('transform', 'rotate(-90)')
      .attr('y', 0 - margin.left)
      .attr('x', 0 - innerHeight / 2)
      .attr('dy', '1em')
      .attr('text-anchor', 'middle')
      .text('Percent Literacy Lacking');

    // X-axis (dependent on id)
    const xScale = d3.scaleLinear()
      .domain([0, d3.max(data, d => d.id)])
      .range([0, innerWidth]);

    // Create bubbles with color coding
    svg.selectAll('.bubble')
      .data(data)
      .enter()
      .append('circle')
      .attr('class', 'bubble')
      .attr('cx', d => xScale(d.id))
      .attr('cy', d => yScale(d.percent_literacy_lacking))
      .attr('r', d => radiusScale(d.population))
      .style('fill', d => {
        // Color coding based on population range
        if (d.population < 100000) {
          return 'green';
        } else if (d.population < 500000) {
          return 'orange';
        } else {
          return 'red';
        }
      })
      .style('opacity', 0.7);

    // Legend
    const legend = d3.select(chartRef.current)
      .append('svg')
      .attr('width', legendWidth)
      .attr('height', legendHeight);

    legend.append('text')
      .attr('x', legendWidth / 2)
      .attr('y', 20)
      .attr('text-anchor', 'middle')
      .style('font-weight', 'bold')
      .text('Population Ranges');

    const legendGroups = legend.selectAll('g')
      .data(['< 100,000', '100,000 - 500,000', '> 500,000'])
      .enter()
      .append('g')
      .attr('transform', (d, i) => `translate(10, ${i * 30 + 30})`);

    legendGroups.append('circle')
      .attr('cx', 5)
      .attr('cy', 5)
      .attr('r', 5)
      .style('fill', d => {
        if (d === '< 100,000') {
          return 'green';
        } else if (d === '100,000 - 500,000') {
          return 'orange';
        } else {
          return 'red';
        }
      });

    legendGroups.append('text')
      .attr('x', 20)
      .attr('y', 5)
      .text(d => d);
  };

  return <div ref={chartRef}></div>;
};

export default BubblePlot;
