import React from 'react';
import BubblePlot from '../components/BubblePlot';
import BarGraph from '../components/BarGraph';
import ProviderScatterPlot from '../components/ProviderScatterPlot';

const ProviderVisualizations = () => {
  return (
    <div style={{ textAlign: 'center' }}>
      <div style={{ marginBottom: '40px' }}> {/* Add margin-bottom */}
        <h1>Literacy vs County Population</h1>
        <BubblePlot />
        <h1>Program Counties</h1>
        <BarGraph />
        <h1>Libraries by Square Footage and Items</h1>
        <ProviderScatterPlot />
        <br></br>
        <div style={{margin: '0 auto', maxWidth: '1000px'}}>
        <h1>Self-Critiques</h1>
        <p>
          <strong>What did we do well?</strong><br />
          We did well with communication. We were all meeting consistently and informing each other of any issues or concerns that came up. This allowed for fast and efficient work to be done.
        </p>
        <p>
          <strong>What did we learn?</strong><br />
          We learned the basics of web development. We also learned how to work in a team in a “professional” environment, which is an invaluable experience that is hard to come by in an academic setting.
        </p>
        <p>
          <strong>What did we teach each other?</strong><br />
          Working together has been very educational because each of our members has their own strengths and approaches to software development. After each member has worked independently we meet up and talk about any problems and solutions we worked through which quickly became a useful learning process that improved our skills.
        </p>
        <p>
          <strong>What can we do better?</strong><br />
          Our group did a great job of volunteering for different tasks at each phase of the project; however, this occasionally led to some overlap in each of our individual assignments. Everyone has their own schedules which can make things hectic but we could have improved on maintaining our roles as it could have boosted our overall productivity.
        </p>
        <p>
          <strong>What effect did the peer reviews have?</strong><br />
          The anonymous CATME peer review surveys allowed us all to receive helpful feedback and improve over the course of the projects. I feel that our productivity and skills improved, and we were able to work through any issues as well as improvement points through the use of this software.
        </p>
        <p>
          <strong>What puzzles us?</strong><br />
          We are largely comfortable with the inner workings of our website. However, one point that intrigues us is the responsiveness of the website. The layout of the website can change and adapt to different screen sizes and zoom patterns, with varying results. The behind-the-scenes work from React to allow for responsive web design is definitely complex, and would warrant further investigation in the future!
        </p>
        <h1>Other Critiques</h1>
        <p>
          <strong>What did they do well?</strong><br />
          The user interface for the website was well done. The design is clean and modern, with a consistent color scheme and typography that enhances readability. The navigation is intuitive, making it easy for users to find what they need quickly.       
           </p>
           <p>
          <strong>How effective was their RESTful API?</strong><br />
          The RESTful API provides the necessary functionality. The Postman documentation was clear and consistent with each of the models and attributes. The APIs were easy to understand and work with when creating the visualizations on our website.
           </p>
           <p>
          <strong>How well did they implement your user stories?</strong><br />
          The implementation of user stories in the project covers most of the essential features. For example, in one phase they successfully implemented sort to work with search. This makes it more effective for users who need to perform more complex queries.
           </p>
           <p>
          <strong>What did we learn from their website?</strong><br />
          We learned how to color different aspects of our website in accordance with our green theme. They used color everywhere around their website so it was useful to see an example.
           </p>
           <p>
          <strong>What can they do better?</strong><br />
          One detail they could have done better was to improve the API response time when you load specific web pages on their website. Sometimes when you load a model page with the grids there is a short delay until you see the material.
           </p>
           <p>
          <strong>What puzzles us about their website?</strong><br />
          One thing that puzzles us about their website is there are no provider visualizations tab located on their website at the moment. This is required according to the rubric.
           </p>


        </div>
      </div>
    </div>
  );
};

export default ProviderVisualizations;
