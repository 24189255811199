import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import InstanceTemplate from "./InstanceTemplate";
import HospitalCardAxios from './HospitalCardAxios';
import ElderHomeCardAxios from './ElderHomeCardAxios'

const capitalize = (token) => {
  return token.charAt(0).toUpperCase() + token.slice(1);
}

const EventInstance = () => {
  const {id} = useParams();
  const url = `https://cs373backend.elderhelpertexas.me/events/${id}`;
  const [info, setInfo] = useState({dataObject: null});

  useEffect(() => {
    const retrieve = async () => {
      const response = await axios.get(url);
      setInfo({dataObject: response.data});
    };
    retrieve();
  }, [url]);

  const data = info.dataObject;
  if (data == null) return (<div></div>);

  const {
    event_category, event_city, event_img_src, event_location, 
    event_paid_status, event_time, event_title, related_homes, 
    related_hospitals
  } = data;
  const spaced_category = event_category.replaceAll('-', ' ');

  return (
    <InstanceTemplate 
      title={event_title}
      content={
        `The event "${event_title}" will happen at ${event_location} in \
        ${event_city}, Texas. It will occur at the time and date of: \
        ${event_time}. This is a ${spaced_category} related event. Note that \
        this is a ${event_paid_status} event.`
      }
      image={event_img_src}
      location={`${event_location}, ${event_city}, Texas`}
      summary={{
        'City': event_city,
        'Location': event_location,
        'Time + Date': event_time,
        'Category': capitalize(spaced_category),
        'Event Type': capitalize(event_paid_status) + ' Event'
      }}
      relatedDetails1={{
        name: 'Hospitals',
        data: related_hospitals,
        AxiosCardType: HospitalCardAxios
      }}
      relatedDetails2={{
        name: 'Elderly Homes',
        data: related_homes,
        AxiosCardType: ElderHomeCardAxios
      }}
    />
  );
}

export default EventInstance;
